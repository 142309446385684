import React, { useState, useEffect } from 'react'
import Marker from '../marker/marker'
import Footer from "../footer/footer"
import Header from "../header/header"
import Home from '../formRequestHome/formRequestHome'
import { Context } from '../context/provider'
import UploadMoreDocuments from '../uploadMoreDocuments/uploadMoreDocuments'
import UploadMultipleSummary from '../uploadMultipleSummary/uploadMultipleSummary'
import { getStorageItem } from '../../utilities/getStorageItem'
import { useSiteConfig } from '../layout/getSiteConfig'

export default function RepoInstructions() {
  const [current, setCurrent] = useState(getStorageItem('mcn-form-filled') === 'true' ? 1 : '');

  const { repoInstructionsInfoApi } = useSiteConfig()

  useEffect(() => {
    if (typeof window != 'undefined') {
      window.scrollTo(0,0)
    }
  }, [current])

  useEffect(() => {
    if (typeof window != 'undefined' && window.location.search.includes('test=')) {
      const search = window.location.search
      const page = search.split('=')[1]
      setCurrent(parseInt(page))
    }
  }, [])

  const markerItems = [
    'Upload documents',
    'Summary',
    'Payment'
  ]

  const formName = 'Repossession Instructions' 
  const description = 'Repossessions are $10. Include repossession affidavit, lien release and certified copies of contract.'

  const requiredItems = <>
    <li>Filled documents with any required signatures</li>
  </>

  useEffect(() => {
    if (current === 2) {
      window.location.href = '/payment'
    }
  }, [current])

  return (
    <Context.Provider value={{ current, setCurrent }}>
      <Header title={parseInt(current) >= 0 ? formName : ''} />
      {parseInt(current) >= 0 && current <= 2 && <Marker items={markerItems}/>}
      {current === '' && <Home form='/repo-instructions.pdf' label={formName} description={description} items={requiredItems} />}
      {current === 0 && <UploadMoreDocuments fee={10} formName={formName} />}
      {current === 1 && <UploadMultipleSummary infoApi={repoInstructionsInfoApi} formPath='repo-instructions'/>}
      <Footer />
    </Context.Provider>
  )
}
