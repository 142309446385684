import React , { useContext } from 'react'
import { Context } from '../context/provider'
import styles from './marker.module.scss'
import PropTypes from 'prop-types'

export default function Marker({ items }) {
  const context = useContext(Context)
  const current = context?.current

  return (
    <section className={styles.section}>
      <div className={styles.items}>
        {items?.map((item, i) => (
          <div key={i} className={styles.item + ` ${current === i ? `${styles.active}` : ""}`}>{item}</div>
        ))}
      </div>
      
      <div className={styles.markers}>
        {items?.map((item, i) => (
          <div key={i} className={styles.marker}>
            <div className={styles.circle + ` ${current >= i ? `${styles.active}` : ""}`}></div>
            {i !== items.length - 1 &&
            <div className={styles.line}>
              <div className={styles.top + ` ${current > i ? `${styles.active}` : ""}`}></div>
              <div className={styles.bottom}></div>
            </div>}
          </div>
        ))}
      </div>
    </section>
  )
}

Marker.propTypes = {
  items: PropTypes.array
}