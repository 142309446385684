import React from 'react'
import styles from './input.module.scss'
import PropTypes from 'prop-types'

export default function Input({ label, name, value, xStyle, classname, invalid, handleChange, children }) {
  const style = [classname, `${invalid.every(x => x) ? styles.invalid : ""}`]
    .filter(x => x)
    .join(' ')

  const xClass = xStyle === 'left' ? styles.xLeft : styles.xRight

  return (
    <div className={styles.field}>
      <label htmlFor={name}>{label}</label>
      <input className={style} id={name} name={name}
        value={value} onChange={handleChange} />
      <div className={xClass + ` ${invalid.every(x => x) ? styles.active : ""}`}>x</div>
      {children}
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  xStyle: PropTypes.string,
  classname: PropTypes.string,
  invalid: PropTypes.array,
  handleChange: PropTypes.func,
  children: PropTypes.node,
}