import React from 'react'
import styles from './checkInput.module.scss'
import PropTypes from 'prop-types'

export default function CheckInput({ values, text }) {
  return (
    <small className={styles.checkInput + ` ${values.every(x => x) ? styles.active : ''}`}>
      {text}
    </small> 
  )
}

CheckInput.propTypes = {
  values: PropTypes.array,
  text: PropTypes.string
}