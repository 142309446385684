import React, { useContext } from 'react'
import Buttons from '../buttons/buttons'
import { Context } from '../context/provider'
import styles from './formRequestHome.module.scss'
import { navigate } from 'gatsby'
import Button from '../buttons/button'
import PropTypes from 'prop-types'

export default function FormRequestHome({ form, label, description, items, children }) {
  const context = useContext(Context)

  function handleClick() {
    const setCurrent = context?.setCurrent

    setCurrent(0)
  }

  const defaultItems = <>
    <li>Driver's license</li>
    <li>Filled form with any required signatures</li>
  </>

  const downloadForm = () => {
    const link = document.createElement("a")
    link.href = form
    link.download = form
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <img className={styles.plate} src="/plate.png" alt="" />
        <h1>Muscogee (Creek) Nation {label}</h1>
        <h2>Before we get started, please ensure you have these items:</h2>
        <ul>
          {items ?? defaultItems}
        </ul>

        {children}

        <Button {...{
          label: 'Download Form',
          direction: 'right',
          onClick: downloadForm
        }} />

        <h4>{description}</h4>

        <Buttons {...{
          handleBack: () => navigate('/'),
          handleNext: handleClick,
          nextLabel: `Let's get started`,
          backLabel: 'BACK'
        }} />
      </div>
    </section>
  )
}

FormRequestHome.propTypes = {
  form: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.node,
  children: PropTypes.node,
}