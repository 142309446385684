import React from 'react'
import styles from './displayItem.module.scss'
import PropTypes from 'prop-types'

export default function DisplayItem({ value, label, display, showCheck = true, total }) {
  if (!value) {
    return null
  }

  const displayVal = display ? display(value) : value

  const valueStyle = styles.value + (total ? ` ${styles.total}` : '')

  return (
    <div className={styles.entry}>
      <div className={styles.key}>{label}</div>
      <div className={valueStyle}>{displayVal}</div>
      {showCheck && <div className={styles.check}><img src="/check.svg" alt="" /></div>}
    </div>
  )
}

DisplayItem.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  display: PropTypes.func,
  showCheck: PropTypes.bool,
  total: PropTypes.bool,
}