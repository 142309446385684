import React, { useState, useContext, useEffect } from 'react'
import styles from './uploadMoreDocuments.module.scss'
import Buttons from '../buttons/buttons'
import ErrorMessage from '../errorMessage/errorMessage'
import { useSiteConfig } from '../layout/getSiteConfig'
import { usePostError } from '../../utilities/usePostError'
import axios from 'axios'
import { Context } from '../context/provider'
import { getStorageItem } from '../../utilities/getStorageItem'
import Loader from 'react-loader-spinner'
import Input from '../input/input'
import MultipleFileUpload from '../multipleFileUpload/multipleFileUpload'
import Filename from '../filename/filename'
import sanitize from 'sanitize-filename'
import { getTransactionId } from '../../utilities/getTransactionId'
import CheckInput from '../checkInput/checkInput'
import { validateEmail } from '../../utilities/validateEmail'
import PropTypes from 'prop-types'

export default function UploadMoreDocuments({ fee }) {
  const [custName, setCustName] = useState(getStorageItem('name'))
  const [email, setEmail] = useState(getStorageItem('email'))
  const [phone, setPhone] = useState(getStorageItem('phone'))
  const [confirmEmail, setConfirmEmail] = useState(getStorageItem('email'))
  const [validEmail, setValidEmail] = useState(false)
  const [formDocs, setFormDocs] = useState([])

  const docNames = getStorageItem('formDocNames')
  const [formDocNames, setFormDocNames] = useState(docNames ? JSON.parse(docNames) : [])
  const [errorMessage, setErrorMessage] = useState('')
  const context = useContext(Context)
  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)

  const { uploadApi } = useSiteConfig()
  const handleError = usePostError()

  const uploadAttachment = async (transactionId, file, fileKey, filename) => {
    const formData = new FormData()
    formData.set(fileKey, file, filename)
    formData.set('id', transactionId)
    const key = process.env.GATSBY_SECRET
    formData.set('key', key)
    await axios.post(uploadApi, formData)
  }

  const uploadAttachments = async (transactionId) => {
    if (formDocs.length === 0) {
      return
    }

    const promises = formDocs.map((doc, idx) =>
      uploadAttachment(transactionId, doc, `document${idx + 1}`, sanitize(doc.name))
    )
    
    await Promise.all(promises);
  }

  const isMatch = email === confirmEmail

  const handleSubmit = async () => {
    setSubmit(true)
    const transactionId = getTransactionId()

    const isEmail = validateEmail(email, setValidEmail)

    const requiredFields = [isEmail, isMatch, custName, phone]

    if (!requiredFields.every(x => x)) {
      return
    }

    const requiredDocs = [
      { name: `Documents are`, value: formDocNames.length > 0 },
    ]

    if (!requiredDocs.every(x => x.value)) {
      setErrorMessage(`${requiredDocs.find(x => !x.value).name} required`)
      return
    }

    setErrorMessage('')
    setLoader(true)

    window.sessionStorage.setItem('mcn-form-filled', true)

    try {
      await uploadAttachments(transactionId)

      const items = {
        formDocNames: JSON.stringify(formDocNames),
        transactionId,
        Fee: fee,
        email,
        name: custName,
        phone,
      }

      Object.entries(items).forEach(([key, value]) => {
        window.sessionStorage.setItem(key, value)
      })

      setSubmit(false)
      setLoader(false)
      context.setCurrent(1)

    } catch(e) {
      handleError(e)
      setLoader(false)
    }
  }

  const handleBack = () => {
    context.setCurrent('')
    setErrorMessage('')
  }

  const handleChange = (e) => {
    const value = e.target.value
    if (e.target.name === 'name') {
      setCustName(value)
    }
    if (e.target.name === 'email') {
      setEmail(value)
      if (submit) {
        validateEmail(value, setValidEmail)
      }
    }
    if (e.target.name === 'confirmEmail') {
      setConfirmEmail(value)
    }
    if (e.target.name === 'phone') {
      setPhone(value)
    }
  }
  
  const updateAttachments = (file) => {
    setFormDocs([...formDocs, file])
    setFormDocNames([...formDocNames, sanitize(file.name)])
  }

  const removeAttachment = (filename) => {
    setFormDocs(formDocs.filter(x => sanitize(x.name) !== filename))
    setFormDocNames(formDocNames.filter(x => x !== filename))
  }

  useEffect(() => {
    window.sessionStorage.setItem('mcn-form-filled', false)
  }, [])

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2>Upload documents</h2>
        <div className={styles.inputs}>
          <Input {...{
            label: 'Name',
            name: 'name',
            value: custName,
            xStyle: 'left',
            handleChange,
            invalid: [submit, !custName],
          }} />
          <Input {...{
            label: 'Phone',
            name: 'phone',
            value: phone,
            xStyle: 'right',
            handleChange,
            invalid: [submit, !phone],
          }} />
        </div>
        <div className={styles.inputs}>
          <Input {...{
            label: 'Email',
            name: 'email',
            value: email,
            xStyle: 'left',
            handleChange,
            invalid: [submit, (!email || !validEmail)],
          }}>
            <CheckInput {...{
              text: 'Please enter a valid email address',
              values: [submit, (!email || !validEmail)],
            }} />
          </Input>
          <Input {...{
            label: 'Confirm email',
            name: 'confirmEmail',
            value: confirmEmail,
            xStyle: 'right',
            handleChange,
            invalid: [submit, (!isMatch || !confirmEmail)],
          }}>
            <CheckInput {...{
              text: 'The email address entered does not match',
              values: [submit, confirmEmail, !isMatch],
            }} />
          </Input>
        </div>
        <div className={styles.uploadButtons}>
          <div>
            <MultipleFileUpload {...{
              label: 'Upload Documents',
              updateAttachments
            }} />
          </div>
          <div>
            {formDocNames?.map((f, idx) => <div key={idx} className={styles.filenames}>
              <Filename  {...{
                removeFile: () => removeAttachment(f),
                filename: f
              }} />
            </div>)}
          </div>
          <ErrorMessage message={errorMessage} />
        </div>
        <Buttons {...{
          handleBack,
          handleNext: handleSubmit,
          backLabel: 'BACK',
          nextLabel: 'NEXT'
        }}>
          {loader && <div className={styles.loader}>
            <Loader type="ThreeDots" color="#2BAF49" height={20} width={80} />
          </div>}
        </Buttons>
      </div>
    </section>
  )
}

UploadMoreDocuments.propTypes = {
  fee: PropTypes.number
}
