import React from 'react'
import styles from './filename.module.scss'
import PropTypes from 'prop-types'

export default function Filename({ filename, removeFile }) {
  if (!filename) {
    return null
  }

  return (
    <p className={styles.filename}>
      {filename}
      <span>
        <button className='remove-file' onClick={removeFile}>x</button>
      </span>
    </p>
  )
}

Filename.propTypes = {
  filename: PropTypes.string,
  removeFile: PropTypes.func,
}