import React from "react"
import Layout from "../components/layout/layout"
import RepoInstructions from "../components/repoInstructions/repoInstructions";

export default function Repo() {
  return (
    <Layout>
      <RepoInstructions />
    </Layout>
  );
}
