import React from 'react'
import styles from './errorMessage.module.scss'
import PropTypes from 'prop-types'

export default function ErrorMessage({ message }) {

  return (
    <p className={styles.errorMessage + ` ${message ? `${styles.active}` : ""}`}>
      {message}
    </p>
  )
}

ErrorMessage.propTypes = {
  message: PropTypes.string
}