export function validateEmail(email, setValidEmail) {
  const testRegxp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  if (testRegxp.test(email)) {
    setValidEmail(true)
    return true

  } else {
    setValidEmail(false)
    return false
  }
}