import React, { useState } from 'react'
import styles from './multipleFileUpload.module.scss'
import PropTypes from 'prop-types'

export default function MultipleFileUpload(props) {
  const { label, updateAttachments } = props;
  const [showTypes, setShowTypes] = useState(false)

  const getAttachmentType = (attachment) => attachment.name.split('.').pop()

  function handleFileChange(e) {
    if (!e.target.files.length) {
      return
    }

    const file = e.target.files[0]
    if (!['jpg', 'jpeg', 'png', 'pdf'].includes(getAttachmentType(file)) || file.size > 5242880)
      setShowTypes(true)
    else {
      setShowTypes(false)
      updateAttachments(file)
    }
  }

  return (
    <div className={styles.fileUpload}>
      <label {...{
        className: styles.upload,
        htmlFor: 'files',
        style: { backgroundImage: 'url(/paper-clip.svg' },
      }}>
        {label}
      </label>
      <input type="file" id='files' name='files' className={styles.hide} onChange={handleFileChange} multiple />

      <p className={styles.fileTypes + ` ${showTypes ? `${styles.show}` : ''}`}>
        Accepted file types: .jpg .png .pdf Max size: 5mb
      </p>
    </div>
  )
}

MultipleFileUpload.propTypes = {
  label: PropTypes.string,
  updateAttachments: PropTypes.func
}